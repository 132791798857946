<template>
  <section id="orders-analytics">
    <b-card-actions
      title="Filters"
      action-collapse
    >
      <b-form-group
        label="Selected Hubs"
      >
        <v-select
          multiple
          label="name"
          :options="hubs"
          v-model="selectedHubs"
          @input="changeSelectedHubs"
        />
      </b-form-group>
      <b-form-group
        label="Date Range"
      >
        <flat-pickr
          class="form-control"
          :config="{ mode: 'range' }"
          v-model="rangeDate"
          @on-change="changeRangeDate"
        />
      </b-form-group>
    </b-card-actions>
    <b-row>
      <b-col
        key="TrendingUpIcon"
        xl="3"
        sm="4"
        class="mb-2 mb-xl-0"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-primary"
              >
                <feather-icon
                  size="24"
                  icon="TrendingUpIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{totals.orders_count}}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Sales
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col
        key="CodeIcon"
        xl="3"
        sm="4"
        class="mb-2 mb-xl-0"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="CodeIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4
                class="font-weight-bolder mb-0"
                v-if="totals.avg_amount">
                {{totals.avg_amount.toFixed(2)}} €
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Avg. order value
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col
        key="DollarSignIcon"
        xl="3"
        sm="4"
        class="mb-2 mb-xl-0"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{totals.total_amount}} €
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total turnover
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col
        key="TruckIcon"
        xl="3"
        sm="4"
        class="mb-2 mb-xl-0"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-warning"
              >
                <feather-icon
                  size="24"
                  icon="TruckIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{totals.shipping_cost}} €
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total shipping
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="6"
        md="6"
      >
        <b-card title="Sales">
          <chartjs-component-line-chart
            v-if="loadedDataChart"
            :data="salesChart.data"
            :options="salesChart.options"
          />
        </b-card>
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
        <b-card title="Average order value">
          <chartjs-component-line-chart
            v-if="loadedDataChart"
            :data="avgOrderValueChart.data"
            :options="avgOrderValueChart.options"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="6"
        md="6"
      >
        <b-card title="Turnover">
          <chartjs-component-line-chart
            v-if="loadedDataChart"
            :data="turnoverChart.data"
            :options="turnoverChart.options"
          />
        </b-card>
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>Average order value per order cycle</b-card-title>
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-bar-chart
              v-if="loadedDataChart"
              :data="avgOrderValueByCycleChart.data"
              :options="avgOrderValueByCycleChart.options"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="6"
        md="6"
      >
        <b-card title="Shipping costs">
          <chartjs-component-line-chart
            v-if="loadedDataChart"
            :data="totalShippingChart.data"
            :options="totalShippingChart.options"
          />
        </b-card>
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody
} from "bootstrap-vue"
import vSelect from "vue-select"
import flatPickr from "vue-flatpickr-component"
import axios from "axios"

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ChartjsComponentLineChart from './charts/chartjs/charts-components/ChartjsComponentLineChart.vue'
import ChartjsComponentBarChart from './charts/chartjs/charts-components/ChartjsComponentBarChart.vue'
import { $themeColors } from "@themeConfig"

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardActions,
    vSelect,
    flatPickr,
    ChartjsComponentLineChart,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      hubs: [],
      selectedHubs: [],
      rangeDate: "",
      totals: {},
      loadedDataChart: false,
      queryParams: {},
      salesChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'top',
            align: 'start',
            display: true,
            labels: {
              usePointStyle: true,
              padding: 15,
              boxWidth: 8,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: true,
                },
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              label: 'Sales',
              data: [],
              lineTension: 0,
              borderColor: "rgba(66, 155, 245, 1)",
              backgroundColor: "rgba(66, 155, 245, 0.2)",
              borderWidth: 2,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 3,
              pointHoverBackgroundColor: '#84D0FF',
              pointHoverBorderColor: $themeColors.light,
            },
          ],
        },
      },
      avgOrderValueChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'top',
            align: 'start',
            display: true,
            labels: {
              usePointStyle: true,
              padding: 15,
              boxWidth: 8,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: true,
                },
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              label: 'Average order value',
              data: [],
              lineTension: 0,
              borderColor: "rgba(66, 155, 245, 1)",
              backgroundColor: "rgba(66, 155, 245, 0.2)",
              borderWidth: 2,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 3,
              pointHoverBackgroundColor: '#84D0FF',
              pointHoverBorderColor: $themeColors.light,
            },
          ],
        },
      },
      turnoverChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'top',
            align: 'start',
            display: true,
            labels: {
              usePointStyle: true,
              padding: 15,
              boxWidth: 8,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: true,
                },
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              label: 'Turnover',
              data: [],
              lineTension: 0,
              borderColor: "rgba(66, 155, 245, 1)",
              backgroundColor: "rgba(66, 155, 245, 0.2)",
              borderWidth: 2,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 3,
              pointHoverBackgroundColor: '#84D0FF',
              pointHoverBorderColor: $themeColors.light,
            },
          ],
        },
      },
      avgOrderValueByCycleChart: {
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: '#28dac6',
              borderColor: 'transparent',
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
              },
            ],
          },
        },
      },
      totalShippingChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'top',
            align: 'start',
            display: true,
            labels: {
              usePointStyle: true,
              padding: 15,
              boxWidth: 8,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: true,
                },
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              label: 'Shipping costs',
              data: [],
              lineTension: 0,
              borderColor: "rgba(66, 155, 245, 1)",
              backgroundColor: "rgba(66, 155, 245, 0.2)",
              borderWidth: 2,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 3,
              pointHoverBackgroundColor: '#84D0FF',
              pointHoverBorderColor: $themeColors.light,
            },
          ],
        },
      },
    }
  },
  methods: {
    getMyHubs() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/hubs/all/my/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
        })
        .then(response => {
          const myHubs = response.data.results.map( item => { return item.name})
          this.hubs = myHubs
          this.selectedHubs = myHubs
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    updateStatisticsChart(data_by_month, data_by_cycleid) {
      this.salesChart.data.labels = data_by_month.map( item => { return new Date(item.year_month).toLocaleDateString("de-DE") })
      this.salesChart.data.datasets[0].data = data_by_month.map( item => { return item.orders_count })
      this.avgOrderValueChart.data.labels = data_by_month.map( item => { return new Date(item.year_month).toLocaleDateString("de-DE") })
      this.avgOrderValueChart.data.datasets[0].data = data_by_month.map( item => { return item.avg_amount })
      this.turnoverChart.data.labels = data_by_month.map( item => { return new Date(item.year_month).toLocaleDateString("de-DE") })
      this.turnoverChart.data.datasets[0].data = data_by_month.map( item => { return item.total_amount })
      this.avgOrderValueByCycleChart.data.labels = data_by_cycleid.map( item => { return item.order_cycle_id })
      this.avgOrderValueByCycleChart.data.datasets[0].data = data_by_cycleid.map( item => { return item.avg_amount })
      this.totalShippingChart.data.labels = data_by_month.map( item => { return new Date(item.year_month).toLocaleDateString("de-DE") })
      this.totalShippingChart.data.datasets[0].data = data_by_month.map( item => { return item.shipping_cost })
      this.loadedDataChart = true
    },
    getOrdersAnalytics() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/orders-analytics/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          this.rangeDate = response.data.range_date
          this.totals = response.data.totals
          this.updateStatisticsChart(response.data.charts_by_month, response.data.charts_by_cycleid)
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    changeSelectedHubs() {
      this.loadedDataChart = false
      this.queryParams["selected_hubs"] = this.selectedHubs
      axios
        .get(process.env.VUE_APP_ROOT_API + "/orders-analytics/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
          params: this.queryParams,
        })
        .then(response => {
          this.totals = response.data.totals
          this.updateStatisticsChart(response.data.charts_by_month, response.data.charts_by_cycleid)
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    changeRangeDate() {
      const dateRange = this.rangeDate.replaceAll(" ", "")
      if (dateRange) {
        this.loadedDataChart = false
        this.queryParams["date_range"] = dateRange
        axios
          .get(process.env.VUE_APP_ROOT_API + "/orders-analytics/", {
            headers: {
              "Authorization": `JWT ${this.$store.state.jwt}`,
              "Content-Type": "application/json"
            },
            params: this.queryParams,
          })
          .then(response => {
            this.totals = response.data.totals
            this.updateStatisticsChart(response.data.charts_by_month, response.data.charts_by_cycleid)
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$router.push({ name: "login" })
            }
          })
      }
    }
  },
  created() {
    this.getMyHubs()
    this.getOrdersAnalytics()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
